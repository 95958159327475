<template>
    <div class="insurance">
        <div class="section-main">
            <h1 style="text-transform: uppercase;">Kalibracja Szyby Samochodowej – Co To Jest i Dlaczego Jest Tak Ważna?
            </h1>
            <div class="aktualnosci-baner">
                <img src="../assets/aktualnosci/main.jpg" />
            </div>

            <h3>Co to jest kalibracja szyby samochodowej?</h3>
            <p>
                Kalibracja szyby samochodowej to proces, który polega na dokładnym dostrojeniu systemów wspomagających
                kierowcę (ADAS – Advanced Driver Assistance Systems) po wymianie lub naprawie szyby. Współczesne
                samochody są wyposażone w zaawansowane technologie, takie jak kamery i czujniki zamontowane na szybie
                czołowej, które wspomagają systemy takie jak:
            </p>
            <ul>
                <li>
                    &#x2022;&nbsp;Automatyczne hamowanie awaryjne
                </li>
                <li>
                    &#x2022;&nbsp;Adaptacyjny tempomat
                </li>
                <li>
                    &#x2022;&nbsp;Systemy utrzymania pasa ruchu
                </li>
                <li>
                    &#x2022;&nbsp;Asystent parkowania
                </li>
            </ul>
            <p>
                Każda ingerencja w szybę może zakłócić działanie tych systemów, dlatego kalibracja jest niezbędna, aby
                przywrócić ich pełną funkcjonalność i precyzję.
            </p>
            <h3>Dlaczego kalibracja szyby jest tak ważna?
            </h3>
            <p>
                <b>
                    Bezpieczeństwo na drodze
                </b>
                <br><br>
                Najważniejszym powodem, dla którego kalibracja szyby samochodowej jest kluczowa, jest bezpieczeństwo.
                Systemy ADAS są zaprojektowane, aby chronić kierowcę i pasażerów, a niewłaściwe działanie tych systemów
                może prowadzić do poważnych wypadków. Precyzyjna kalibracja zapewnia, że wszystkie czujniki i kamery
                działają poprawnie, co przekłada się na większe bezpieczeństwo na drodze.
                <br><br>
                <b>
                    Precyzja działania systemów ADAS
                </b>
                <br><br>
                Każda, nawet najmniejsza, nieprawidłowość w ustawieniu kamer czy czujników może prowadzić do błędnych
                odczytów. Kalibracja gwarantuje, że wszystkie systemy wspomagające kierowcę działają z najwyższą
                precyzją, co jest niezbędne do ich skutecznego działania.
            </p>
            <h3>Jakie są etapy kalibracji szyby samochodowej?
            </h3>
            <p>
                <b>
                    1. Wstępna inspekcja pojazdu
                </b>
                <br><br>
                Przed przystąpieniem do kalibracji, technik przeprowadza dokładną inspekcję pojazdu, aby zidentyfikować
                ewentualne uszkodzenia oraz upewnić się, że wszystkie systemy działają poprawnie.
                <br><br> <b>
                    2. Ustawienie pojazdu
                </b>
                <br><br>
                Pojazd musi być ustawiony w odpowiednich warunkach, zazwyczaj w specjalnie przystosowanym warsztacie lub
                na płaskim terenie, aby zapewnić dokładność kalibracji.
                <br><br><b>
                    3. Podłączenie sprzętu kalibracyjnego
                </b>
                <br><br>
                Technik podłącza specjalistyczne urządzenia kalibracyjne, które komunikują się z systemami ADAS w
                pojeździe.
                <br><br><b>
                    4. Przeprowadzenie kalibracji
                </b>
                <br><br>
                Kalibracja może być przeprowadzona statycznie (w warsztacie) lub dynamicznie (podczas jazdy). Proces ten
                polega na dostrojeniu wszystkich czujników i kamer, aby działały z najwyższą precyzją.
                <br><br> <b>
                    5. Testowanie systemów
                </b>
                <br><br>
                Po zakończeniu kalibracji technik przeprowadza testy, aby upewnić się, że wszystkie systemy ADAS
                działają poprawnie i są gotowe do użycia.
            </p>
            <h3>
                Jaki jest efekt wykonania kalibracji?
            </h3>
            <p>
                <b>
                    Pełna funkcjonalność systemów ADAS
                </b>
                Efektem prawidłowo wykonanej kalibracji po wymianie szyby samochodowej jest pełna funkcjonalność
                wszystkich systemów wspomagających kierowcę. Dzięki temu, kierowca może być pewny, że systemy te
                działają skutecznie i dokładnie, co znacząco zwiększa bezpieczeństwo na drodze.
                <b>
                    Spokój ducha
                </b>
                Wiedząc, że po wymianie i naprawie szyby samochodowej systemy ADAS są skalibrowane i działają poprawnie,
                kierowca może czuć się pewniej i bezpieczniej podczas jazdy.
            </p>
            <h3>
                Branżowe ciekawostki
            </h3>
            <p>
                Czy wiesz, że niektóre systemy ADAS są tak zaawansowane, że potrafią przewidzieć kolizję na kilka sekund
                przed jej wystąpieniem i automatycznie zahamować? To właśnie dzięki precyzyjnej kalibracji te systemy
                działają bezbłędnie.
            </p>
            <h3>
                Umów się na kalibrację w firmie Jurpol Szyby Samochodowe
            </h3>
            <p>
                Jeżeli wymieniałeś szybę w swoim samochodzie lub zauważyłeś, że systemy wspomagające kierowcę nie
                działają tak, jak powinny, umów się na kalibrację w firmie Jurpol Szyby Samochodowe. Nasi specjaliści
                zadbają o to, aby wszystkie systemy ADAS w Twoim samochodzie działały z najwyższą precyzją, zapewniając
                Ci bezpieczeństwo i komfort jazdy.<br><br>
                Skontaktuj się z nami już dziś i zadbaj o swoje bezpieczeństwo na drodze!
                <br><br>
                <b>email:&nbsp;</b><a href="mailto: biuro@jurpol.pl"
                    style="text-decoration: none; color: black;">biuro@jurpol.pl</a>
                <br><br>
                <b>tel.:&nbsp;</b>
                <a href="tel:618107617" style="text-decoration: none; color: black;">61 810 76 17</a>
            </p>
            <br><br>
            <br>
            <div class="gallery-wrapper">
                <div class="gallery-piece">
                    <img src="../assets/aktualnosci/gallery1.jpg" />
                </div>
                <div class="gallery-piece">
                    <img src="../assets/aktualnosci/gallery2.jpg" />
                </div>
                <div class="gallery-piece">
                    <img src="../assets/aktualnosci/gallery3.jpg" />
                </div>
                <div class="gallery-piece">
                    <img src="../assets/aktualnosci/gallery4.jpg" />
                </div>
                <div class="gallery-piece">
                    <img src="../assets/aktualnosci/gallery5.jpg" />
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>

<script>
export default {
    name: 'AktualnosciPage',
};
</script>

<style scoped>
.aktualnosci-baner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.gallery-wrapper {
    display: flex;
    flex-wrap: wrap;

    .gallery-piece {
        width: 50%;
        padding: 2rem;
        transition: transform .2s;

        img {
            width: 100%;
        }

        &:hover {
            transform: scale(1.2);
        }
    }
}


@media (max-width: 1150px) {
    .gallery-wrapper {
        flex-direction: column;

        .gallery-piece {
            width: 100%;
        }
    }
}
</style>